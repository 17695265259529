import axios from 'axios';
import  env  from "react-dotenv";

const apiClient = axios.create({baseURL: env.API_URL, timeout: 300000});

/* Promise que obtiene un token a partir de un json con usuario y password */
const fetchToken = (credentials) => {
  apiClient.defaults.headers.common = { "Accept": `application/json`};
  return apiClient.post("/auth/login", credentials);
}

/* Promise que obtiene la info de la cuenta*/
const fetchUserMe = (token) => {
  apiClient.defaults.headers.common = {
    "Authorization": `Bearer ${token}`,
    "Accept": `application/json`
  };

  let xArea = localStorage.getItem("areaId");

  if (xArea) {
    apiClient.defaults.headers.common = {
      ...apiClient.defaults.headers.common,
      "X-Area": xArea
    };

  }
  return apiClient.get("/users/me");
}

export { fetchToken };
export { fetchUserMe };

export default apiClient;
