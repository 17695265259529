import moment from 'moment-timezone';
import materialTableLocalization from '../../../../helpers/localization/materialTableLocalization.jsx';

import React from 'react';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import { Chip, Tooltip } from '@material-ui/core';
import decimalToHumanHours from '../../../../helpers/dateUtils/dateFormatter';

/* Material table title */
const title = "Seguimiento de actividades";

/* Material table columns defs. table activity */
const columns = [
  {
    title: 'Usuario',
    field: 'user_id',
    editable: 'never',
    width: "15%" ,
    lookup: {},
    render: (rowData) => [rowData.user.lastname,rowData.user.firstname ,rowData.user.middlename].join(" ")
  },
  { 
    title: 'Inicio',
    field: 'start',
    render: rowData => moment(rowData.start).format('DD/MM/YY HH:mm'),
    type:'date',
    editable: 'never',
    width: "10%",
    filterPlaceholder: "Desde"
  },
  { 
    title: 'Fin',
    field: 'end',
    render: rowData => rowData.end=== null ? "En curso" : moment(rowData.end).format('DD/MM/YY HH:mm'),
    type:'date',
    width: "10%",
    filterPlaceholder: "Hasta"
  },
  {
    title: 'Duración',
    editable: 'never',
    width: "10%",
    render: (rowData) => {
      return decimalToHumanHours(rowData.duration / 60);
    },
    align: 'center'
  },
  {
    title: 'Saldo horas del periodo',
    field: 'hours_balance',
    editable: 'never',
    width: "10%",
    render: (rowData) => {
      const color = rowData.hours_balance > 0 ? "primary" : rowData.hours_balance < 0 ? "secondary" : "default";
      return  <Chip
                label= { (rowData.hours_balance < 0 ? "- ": "") + decimalToHumanHours(Math.abs(rowData.hours_balance)) }
                color={color}
              />
    },
    align: 'center'
  },
  {
    title: 'Categoría',
    field: 'type.category.id',
    editable: 'never',
    width: "10%",
    lookup: {},
    render: (rowData) => rowData.type.category.name
  },
  {
    title: 'Área',
    field: 'type.area.id',
    editable: 'never',
    width: "15%",
    lookup: {},
    render: (rowData) => rowData.type.area.name
  },
  {
    title: 'Tipo de actividad',
    field: 'type.id',
    editable: 'never',
    width: "15%",
    lookup: {},
    render: (rowData) => rowData.type.name
  },
  {
    title: 'Estado',
    field: 'status_id',
    editable: 'never',
    width: "10%",
    lookup: {},
    render: (rowData) => rowData.status_name
  },
  {
    title: '',
    hidden: false,
    field: 'lunch',
    render: rowData =>  {
      return(rowData.lunch === 1 ? <LocalDiningIcon
          color="secondary"
          fontSize="large"
        /> : "")
    },
    type:'numeric',
    align: 'center',
    editable: 'never',
    export: true,
    filtering: false,
    filterPlaceholder: "Hasta"
  },
];

/* Material table options. table trace-activities */
const options =
{
  editable: false,
  search: false,
  pageSizeOptions: [10, 20, 50, 100, 500],
  exportButton: true,
  exportAllData: true,
  sorting: true,
  actionsColumnIndex: -1,
  toolbarButtonAlignment:'left',
  initialPage: 0,
  filtering: false
}

export default columns;
export { title, materialTableLocalization as localization, options };
